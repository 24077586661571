import { store } from "../store/store";
import { Provider } from "react-redux";
import { ThemeConfig, PrivateRoute, NavSidebar, Box } from "@enerbit/base";
import SectionInformation from "../pages/SectionInformation";
import MainLayout from "../layouts/MainLayout";

export default function Routers() {
  return (
    <Provider store={store}>
      <ThemeConfig>
        <PrivateRoute>
          <MainLayout>
            <SectionInformation />
          </MainLayout>
        </PrivateRoute>
      </ThemeConfig>
    </Provider>
  );
}
