import * as singleSpa from "single-spa";

const mountedAppSingleSpaInfoBilling = () => {
  singleSpa.registerApplication(
    "app-info-billing",
    (): Promise<singleSpa.LifeCycles> => System.import("@enerBit/infoBilling"),
    (location) => location.pathname.startsWith("/")
  );
};

const mountedAppSingleSpaPaymentsOverview = () => {
  singleSpa.registerApplication(
    "app-payments-overview",
    (): Promise<singleSpa.LifeCycles> => System.import("@enerBit/payments-overview"),
    (location) => location.pathname.startsWith("/")
  );
};

const mountedAppSingleSpaOperaOveview = () => {
  singleSpa.registerApplication(
    "app-opera-overview",
    (): Promise<singleSpa.LifeCycles> => System.import("@enerBit/opera-overview"),
    (location) => location.pathname.startsWith("/")
  );
};

export const mountedDisassembleAppInfoBilling = async () => {
  const appInfoBilling = singleSpa.getAppNames().find((item) => item == "app-info-billing");

  if (appInfoBilling) {
    singleSpa
      .unregisterApplication("app-info-billing")
      .then(() => mountedAppSingleSpaInfoBilling());
  } else {
    mountedAppSingleSpaInfoBilling();
  }
};

export const mountedDisassembleAppPaymentsOverview = async () => {
  const appPaymentsOverview = singleSpa
    .getAppNames()
    .find((item) => item == "app-payments-overview");

  if (appPaymentsOverview) {
    singleSpa
      .unregisterApplication("app-payments-overview")
      .then(() => mountedAppSingleSpaPaymentsOverview());
  } else {
    mountedAppSingleSpaPaymentsOverview();
  }
};

export const mountedDisassembleAppOperaOverview = async () => {
  const appOperaOverview = singleSpa.getAppNames().find((item) => item == "app-opera-overview");

  if (appOperaOverview) {
    singleSpa
      .unregisterApplication("app-opera-overview")
      .then(() => mountedAppSingleSpaOperaOveview());
  } else {
    mountedAppSingleSpaOperaOveview();
  }
};
