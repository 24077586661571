import { Box, Tabs, Tab } from "@enerbit/base";

import { useEffect, useState } from "react";
import {
  mountedDisassembleAppInfoBilling,
  mountedDisassembleAppOperaOverview,
} from "../helpers/helperSingleSpa";
import { mountedDisassembleAppPaymentsOverview } from "../helpers/helperSingleSpa";
import { Transactions } from "./TransactionsIframe/Transactions";
import Frontiers from "./FontiersIframe/Frontiers";

const SectionInformation = () => {
  const storedTab = localStorage.getItem("prisma-tab");
  const initialValue = storedTab !== null ? +storedTab : 0;
  const [value, setValue] = useState<number>(initialValue);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    localStorage.setItem("prisma-tab", `${newValue}`);
    setValue(newValue);
  };

  const tabs = [
    {
      label: "Facturas",
      component: <div id='single-spa-application:app-info-billing'></div>,
    },
    {
      label: "Pagos",
      component: <div id='single-spa-application:app-payments-overview'></div>,
    },
    {
      label: "Transacciones",
      component: <Transactions />,
    },
    { label: "Ópera", component: <div id='single-spa-application:app-opera-overview'></div> },
    {
      label: "Fronteras y CGM",
      component: <Frontiers />,
    },
  ];

  useEffect(() => {
    if (value === 0) {
      mountedDisassembleAppInfoBilling();
    } else if (value === 1) {
      mountedDisassembleAppPaymentsOverview();
    } else if (value === 3) {
      mountedDisassembleAppOperaOverview();
    }
  }, [value]);

  return (
    <Box className='Component-root' sx={{ left: "unset !important" }}>
      <Box className='Title-main-information'>Prisma</Box>
      <Box sx={{ width: "100%", mt: "10px" }}>
        <Box className='Box-tabs-main'>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
            TabIndicatorProps={{
              style: { backgroundColor: "orange", color: "black" },
            }}>
            {tabs.map(({ label }, i) => (
              <Tab className='Custom-tabs' label={label} key={i} />
            ))}
          </Tabs>
        </Box>
        {tabs.map((values, indexS) => {
          return (
            <Box
              key={indexS}
              role='tabpanel'
              style={{ display: value != indexS ? "none" : "block" }}
              id={`simple-tabpanel-${indexS}`}
              aria-labelledby={`simple-tab-${indexS}`}>
              <Box>{values.component}</Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default SectionInformation;
