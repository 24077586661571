import React from "react";

const Frontiers = () => {
  return (
    <>
      <iframe src={process.env.REACT_APP_FRONTIERS_DASHBOARD_URL} height='800' width='100%' />
    </>
  );
};

export default Frontiers;
